





























import {Component, Vue} from 'vue-property-decorator';
import {PlaylistService} from '@/api';
import {createPlaylist} from '@/types';
import UTabs from '@/ui-lib/tabs/Tabs.vue';
import UTab from '@/ui-lib/tabs/Tab.vue';

@Component<PlaylistItemView>({
  components: {
    UTab,
    UTabs,
  },

  metaInfo() {
    return {
      title: this.title,
    };
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const pl = await PlaylistService.item(parseInt(to.params.id, 10));

      next((vm) => {
        vm.playlist = pl;
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      this.playlist = await PlaylistService.item(parseInt(to.params.id, 10));
      next();
    } catch (e) {
      next(e);
    }
  },
})
export default class PlaylistItemView extends Vue {
  private playlist = createPlaylist();

  private get title() {
    return `Плейлист: ${this.playlist.title}`;
  }
}
